import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { CharitiesWeServe } from 'components/CharitiesWeServe'
import { CharitiesGallery } from 'components/CharitiesGallery'
import { GivesBack } from 'components/GivesBack'
import { AboutCTA } from 'components/AboutCTA'
import { CharitiesHero } from 'components/CharitiesHero'

const AboutPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <CharitiesHero />
      <CharitiesWeServe />
      <CharitiesGallery />
    </EllicotLayout>
  )
}

export default AboutPage
