import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import rHSC from '../images/RHSC.png'
import strides from '../images/strides-for-stroke.png'

const CharitiesWeServeWrapper = styled.div`
  margin: auto;
  padding: 5em 0 10em;
`

const CTATitle = styled.h2`
  font-family: CentraNo2-Bold;
  color: #000;
  letter-spacing: 1px;
  font-size: 2rem;
  text-align: center;
`

const CharitiesText = styled.p`
  max-width: 50%;
  margin: auto;
  font-family: CentraNo2-Book;
  font-size: 1.25em;
  text-align: center;
  line-height: 1.5;
`

const GrayImg = styled.img`
  filter: grayscale(100%);
`

export const CharitiesWeServe: React.FunctionComponent = () => {
  return (
    <CharitiesWeServeWrapper>
      <FlexDiv style={{ flexFlow: 'column', padding: '0.5rem' }}>
        <CTATitle> Commited To The Communities We Serve</CTATitle>
        <CharitiesText>
          Ellicott Realty Inc. is deeply rooted in the core values of the local
          communities for which we serve. We are committed to giving back and
          lending a helping hand to the communities that have given us so much.
        </CharitiesText>
      </FlexDiv>
      <div style={{ display: 'flex', padding: '5em 10em 0em' }}>
        <FlexDiv
          style={{
            justifyContent: 'center',
            alignSelf: 'flex-start',
            alignContent: 'center',
          }}
        >
          <GrayImg src="https://northwhick.netlify.app/ellicot-assets/charities/Chum.png" />
        </FlexDiv>
        <FlexDiv
          style={{
            justifyContent: 'center',
            placeItems: 'flex-start',
            alignContent: 'center',
          }}
        >
          {' '}
          <GrayImg src={rHSC} />
        </FlexDiv>
        <FlexDiv
          style={{
            justifyContent: 'center',
            placeItems: 'flex-start',
            alignContent: 'center',
          }}
        >
          {' '}
          <img src="https://northwhick.netlify.app/ellicot-assets/charities/arts-helps-logo.png" />
        </FlexDiv>
      </div>
      <div style={{ display: 'flex', padding: '5em 10em 0em' }}>
        <FlexDiv
          style={{
            justifyContent: 'center',
            placeItems: 'flex-start',
            alignContent: 'center',
          }}
        >
          <GrayImg src="https://northwhick.netlify.app/ellicot-assets/charities/ward-4.png" />
        </FlexDiv>
        <FlexDiv
          style={{
            justifyContent: 'center',
            placeItems: 'flex-start',
            alignContent: 'center',
          }}
        >
          <GrayImg src={strides} />
        </FlexDiv>
        <FlexDiv
          style={{
            justifyContent: 'center',
            placeItems: 'flex-start',
            alignContent: 'center',
          }}
        >
          <GrayImg src="https://northwhick.netlify.app/ellicot-assets/charities/SickKids.png" />
        </FlexDiv>
      </div>
    </CharitiesWeServeWrapper>
  )
}
