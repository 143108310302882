import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import bgimg from '../images/CharitiesHeader.jpg'

const OurPromiseWrapper = styled.div`
  margin: auto;
  padding: 10em 0 10em;
  display: flex;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(6, 6, 6, 0.5)),
    url(https://northwhick.netlify.app/ellicot-assets/charities/CharitiesHeader.jpg);
  background-size: cover;
`

const CTATitle = styled.h2`
  font-family: CentraNo2-Bold;
  color: #fff;
  letter-spacing: 1px;
  font-size: 3.75rem;
  text-align: center;
`

const CTAWrapper = styled.div``

export const CharitiesHero: React.FunctionComponent = () => {
  return (
    <CTAWrapper>
      <OurPromiseWrapper
        style={{
          backgroundImage: 'url(' + bgimg + ')',
        }}
      >
        <FlexDiv style={{ flexFlow: 'column', padding: '0.5rem' }}>
          <CTATitle> #EllicottGivesBack</CTATitle>
        </FlexDiv>
      </OurPromiseWrapper>
    </CTAWrapper>
  )
}
