import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'

const GalleryWrapper = styled.div`
  margin: auto;
  padding: 10em 0 0;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  max-width: 90%;
`

const GalleryWrapper2 = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  padding: 0 0 15em;
  max-width: 90%;
`

const CTATitle = styled.h3`
  font-family: CentraNo2-Bold;
  letter-spacing: 1px;
  font-size: 2.75rem;
  color: #000;
`

const CTAWrapper = styled.div``

export const CharitiesGallery: React.FunctionComponent = () => {
  return (
    <CTAWrapper>
      <GalleryWrapper>
        <div
          style={{
            display: 'inline-flex',
            placeContent: 'center',
            paddingBottom: '1em',
          }}
        >
          <FlexDiv
            style={{
              flex: '0 0 23.5%',
            }}
          >
            <img src="https://northwhick.netlify.app/ellicot-assets/charities/chum-toy-drive.jpg" />
          </FlexDiv>
          <FlexDiv
            style={{
              flex: '0 0 23.5%',
            }}
          >
            <img src="https://northwhick.netlify.app/ellicot-assets/charities/RHSC.jpg" />
          </FlexDiv>
          <FlexDiv
            style={{
              flex: '0 0 47%',
              justifyContent: 'initial',
            }}
          >
            <iframe
              width="100%"
              height="auto"
              src="https://www.youtube.com/embed/U_yasKdeo8c"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </FlexDiv>
        </div>
      </GalleryWrapper>
      <GalleryWrapper2>
        <div style={{ display: 'inline-flex', placeContent: 'center' }}>
          <FlexDiv
            style={{
              flex: '0 0 47%',
              justifyContent: 'space-evenly',
            }}
          >
            <iframe
              width="100%"
              height="auto"
              src="https://www.youtube.com/embed/U_yasKdeo8c"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </FlexDiv>
          <FlexDiv
            style={{
              flex: '0 0 23.5%',
              justifyContent: 'flex-end',
            }}
          >
            <img src="https://northwhick.netlify.app/ellicot-assets/charities/arts-helps.jpg" />
          </FlexDiv>
          <FlexDiv
            style={{
              flex: '0 0 23.5%',
              justifyContent: 'space-evenly',
            }}
          >
            <img src="https://northwhick.netlify.app/ellicot-assets/charities/maple-syrup.jpg" />
          </FlexDiv>
        </div>
      </GalleryWrapper2>
    </CTAWrapper>
  )
}
